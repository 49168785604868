@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    scroll-behavior: smooth;
  }
}
@layer base {
    html {
      font-family: display;
    }
  }
  