
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.width-100 {
  width: 100%;
}
video {
  height: 697px;
  width: 100%;
  object-fit: cover;
  opacity: 80%;
}
.address p {
  padding: 0 10px;
}
.header-down {
  max-height: 690px;
}
.images-up {
  margin-top: 57px;
}
.text-bg-color {
  background-color: #ff1744;
}
.text-bg-color-grey {
  background-color: #fafafa;
}
.google-map-code {
  width: 100%;
  overflow: hidden;
}
.google-map-code > iframe {
  width: 100%;
  height: 400px;
  border: none;
}
.animation-delay {
  animation-delay:0.8s;
}
.iv-height {
  height: 360px;
}
@media (min-width: 320px) {
  video {
    height: 497px;
  }
}
@media (min-width: 375px) {
  video {
    height: 358px;
  }
}
@media (min-width: 425px) {
  video {
    height: 358px;
  }
}
@media (min-width: 768px) {
  video {
    height: 552px;
  }
}
@media (min-width: 1024px) {
  video {
    height: 697px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
